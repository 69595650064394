import { Component, OnInit } from '@angular/core';
import {IMAGE_LOGO} from '../const/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logo = IMAGE_LOGO;

  constructor() { }

  ngOnInit() {
  }
}
