export class User {
  gender: string;
  firstName: string;
  lastName: string;
  zip: string;
  city: string;
  street: string;
  streetNumber: string;
  email: string;
  phone: string;
  nameChild: string;
  birthChild: string;
  medicationChild: string;
  message?: string;
  privacy: string;
  telconsent: string;
}
