<section>
  <div class="embed-responsive embed-responsive-16by9">
    <video muted controls autoplay controlsList="nodownload" class="embed-responsive-item" poster="../assets/img/headerVideoPoster.jpg">
      <source src="../assets/video/Nuerenta.mp4" type="video/mp4">
    </video>
  </div>
</section>

<section class="headertitle">
  <div class="container py-5">
    <div class="row justify-content-center">
      <h1 class="header-text text-center">
        <span>Dein kostenloses</span>
        <span class="font-weight-bold geschenkpäckchen-color"> Geschenkpäckchen</span>
      </h1>
    </div>
    <div class="row justify-content-center">
      <h2 class="header-subtext text-center">Herzlichen Glückwunsch zur Geburt deines Kindes</h2>
    </div>
    <div id="heards-img-1">
      <img [src]="heardsIMGOne">
    </div>
  </div>
</section>

<section class="icon-view">
  <div class="container py-5">
    <div class="row mt-3">
      <div class="col-12">
        <h2 class="content-view-header text-center">Inhalt</h2>
        <hr class="hr-bar">
      </div>
    </div>
    <div class="row mt-5 justify-content-center smaller-devices-mt-spacing">
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-4 text-center">
        <div class="rectangle">
          <img alt="iconOne" [src]="iconOne" class="icon-one mt-3">
          <p class="mt-4 rectangle-text">
            Personalisierter Schutzengel-Anhänger
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-4 text-center">
        <div class="rectangle">
          <img alt="iconTwo" [src]="iconTwo" class="mt-3">
          <p class="mt-4 rectangle-text">
            Exklusive<br/> Babyausstattung
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-4 text-center">
        <div class="rectangle">
          <img alt="iconThree" [src]="iconThree" class="icon-three mt-3">
          <p class="mt-2 rectangle-text">
            Kostenloses Krankenhaustagegeld<br/>für 1 Jahr
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="agency">
  <div class="container py-5">
    <div class="row mt-3">
      <div class="col-12">
        <h2 class="content-view-header text-center">Bei uns stehst Du und Deine Familie im Mittelpunkt</h2>
        <hr class="hr-bar">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xs-12 col-s-12 col-md-5 col-lg-5 py-5 smaller-devices-py-spacing-picture">
        <img class="d-block mx-auto mx-md-0" alt="person" [src]="personIMG" style="width:100%;">
      </div>
      <div class="col-xs-12 col-s-12 col-md-7 col-lg-7 mt-5 agency-middle agency-text smaller-devices-mt-spacing">
        <p>„Als Agentur der NÜRNBERGER Versicherung haben wir uns in den letzten 10 Jahren auf die Vorsorge junger
          Familien und die Gesundheitsvorsorge neugeborener Kinder spezialisiert. Gerade die Absicherung Eurer Kinder
          liegt uns besonders am Herzen! Getreu unserem Motto - Es ist besser eine Versicherung zu haben und nicht zu
          brauchen als Keine zu haben und zu benötigen.“<br><br>Thomas Hoesl, Geschäftsführer NüRenta GmbH</p>
      </div>
    </div>
    <div id="heards-img-2" class="float-right">
      <img [src]="heardsIMGTwo">
    </div>
  </div>
</section>

<section class="content-view">
  <div class="container py-5">
    <div class="row mt-3">
      <div class="col-12 text-center">
        <h2 class="content-view-header text-center">Kostenloses Geschenke&shy;päckchen</h2>
        <hr class="hr-bar">
        <p class="package-subtext">Exklusive Babyausstattung, Stofftiere & 1 Jahr kostenlose Versicherung</p>
      </div>
    </div>
    <div class="row justify-content-center mt-5 smaller-devices-mt-spacing">
      <div class="col-xs-10 col-md-8 col-lg-4">
        <img alt="iconOne" [src]="packageOneIMG" class="image-content">
        <h5 class="mt-3 text-left">Kuschelalarm</h5>
        <p class="text-left image-content-text">Die Stofftiere Maja, Willi & Flip kommen bei den Kleinsten immer gut
          an
          und werden schnell zum besten Freund der die Kinder überall hin begleiten muss.
        </p>
      </div>
      <div class="col-xs-10 col-md-8 col-lg-4">
        <img alt="iconTwo" [src]="packageTwoIMG" class="image-content">
        <h5 class="mt-3 text-left">Mamas & Papas Schatz</h5>
        <p class="text-left image-content-text">Mit der individuellen „Mamas & Papas Schatz“ – Ausstattung wird dein
          Baby der Star auf jeder Babyparty.</p>
      </div>
      <div class="col-xs-10 col-md-8 col-lg-4">
        <img alt="iconThree" [src]="packageThreeIMG" class="image-content">
        <h5 class="mt-3 text-left">Schutzengel</h5>
        <p class="text-left image-content-text">Der personalisierte Schutzengel-Anhänger soll Deinem Kind stets Glück
          und Sicherheit bringen.</p>
      </div>
    </div>
  </div>
</section>

<section class="insurance">
  <div class="container py-5">
    <div class="row mt-3">
      <div class="col-12">
        <h2 class="content-view-header text-center">1 Jahr kostenlose Krankenhaus&shy;tagegeld&shy;versicherung</h2>
        <hr class="hr-bar">
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-xs-12 col-s-12 col-md-7 col-lg-7 mt-5 insurance-text insurance-middle smaller-devices-mt-spacing">
        <ul class="insurance-ul">
          <li class="li-dot"><span class="li-span">100% des versicherten Krankenhaustagegeldes für jeden vollen Tag einer medizinisch notwendigen stationären
            Heilbehandlung oder Entbindung Leistung</span></li>
          <li class="li-dot"><span
            class="li-span">Aufnahme- und Entlassungstag gelten jeweils als ein voller Tag</span>
          </li>
          <li class="li-dot"><span class="li-span">zeitlich unbegrenzt und ohne Kostennachweis</span></li>
          <li class="li-dot"><span class="li-span">3 Monate Wartezeit (entfällt bei einem Unfall)</span></li>
        </ul>
      </div>
      <div class="col-xs-12 col-s-12 col-md-5 col-lg-5 py-5 smaller-devices-py-spacing-picture">
        <img class="d-block mx-auto mx-md-0" alt="person" [src]="freeInsuranceTwoIMG" style="width:100%;">
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container py-5">
    <div class="row mt-3">
      <div class="col-12">
        <h2 class="content-view-header text-center">Häufige Fragen</h2>
        <hr class="hr-bar">
      </div>
    </div>
    <div class="card question-box mt-5 smaller-devices-mt-spacing">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
           aria-controls="collapseOne">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Warum ist das
          Geschenkepäckchen kostenlos? </p>
      </div>
      <div id="collapseOne" class="collapse" aria-labelledby="headingOne">
        <div class="card-body">
          <p>Wir machen das als Aktion um neue Kunden zu gewinnen, und Familien die notwendigen Absicherungs- und
            Vorsorgelösungen näher zu bringen, welche für Dich und Deine Kinder wichtig und sinnvoll sind.</p>
        </div>
      </div>
    </div>
    <div class="card question-box mt-3">
      <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
           aria-controls="collapseTwo">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Wer steckt hinter der
          NüRenta
          GmbH? </p>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
        <div class="card-body">
          <p>Wir sind eine Generalagentur der NÜRNBERGER Versicherung. Unsere Aufgaben sind das Verwalten und
            Vermitteln
            von Versicherungsverträgen. Zahlreiche Mitarbeiter/innen im Innen- und Außendienst vertreten im gesamten
            Bundesgebiet unser Unternehmen.
            Wir sind im gesamten Bereich des Familienschutzes tätig, denn die Absicherung junger Familien liegt uns
            besonders am Herzen. Wir begleiten Dich in allen Versicherungsfragen, bis ins hohe Alter! Unser Hauptsitz
            mit Verwaltung ist in Dortmund. Mehr Information findest du unter <a href="https://www.nuerenta.de/">www.nuerenta.de</a>
          </p>
        </div>
      </div>
    </div>
    <div class="card question-box mt-3">
      <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
           aria-expanded="false" aria-controls="collapseThree">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Welche Leistungen bietet
          die
          Krankenhaustagegeld&shy;versicherung? </p>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
        <div class="card-body">
          <p>Das Krankenhaustagegeld von 10,00 € für Kinder unter 18 Jahre zahlen wir für die Dauer von einem Jahr. Ab
            dem zweiten Jahr kostet es dich dann lediglich 49 Cent pro Monat. Die Laufzeit für die Versicherung
            beträgt
            2 Jahre und verlängert sich automatisch sofern du nicht kündigst (Kündigungsfrist 3 Monate).
          </p>
          <p> Folgende Leistungen bietet die Versicherung:</p>
          <ul>
            <li>100% des versicherten Krankenhaustagegeldes für jeden vollen Tag einer medizinisch notwendigen
              stationären Heilbehandlung oder Entbindung
            </li>
            <li>Aufnahme- und Entlassungstag gelten jeweils als ein voller Tag</li>
            <li>Leistung zeitlich unbegrenzt und ohne Kostennachweis</li>
            <li>3 Monate Wartezeit (entfällt bei einem Unfall)</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card question-box mt-3">
      <div class="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour"
           aria-expanded="false"
           aria-controls="collapseFour">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Wünschst Du für Dein Kind
          auch eine Chefarztbehandlung oder die Unterbringung in einem Ein-/Zweibettzimmer?</p>
      </div>
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
        <div class="card-body">
          <p>Selbstverständlich kannst Du auch weitere Zusatzversicherungen für eine schnelle Genesung Deines Kindes
            bei
            uns bekommen. Schreibe uns hierzu einfach eine kurze Nachricht wenn Du das Geschenkepäckchen bestellst und
            wir melden uns umgehend bei Dir.</p>
        </div>
      </div>
    </div>
    <div class="card question-box mt-3">
      <div class="card-header" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
           aria-expanded="false"
           aria-controls="collapseFive">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Was musst Du tun um das
          kostenlose Geschenkepäckchen zu bestellen?</p>
      </div>
      <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
        <div class="card-body">
          <p>Fülle dazu einfach das Formular (Klick auf „Bestellen“) aus. Sobald Deine Daten bei uns eingegangen
            sind, werden wir Dich anrufen um Dir das Krankenhaustagegeld noch abschließend zu erläutern. Achte deshalb
            bitte unbedingt auf die Angabe Deiner korrekten Telefonnummer.</p>
        </div>
      </div>
    </div>
    <div class="card question-box mt-3">
      <div class="card-header" id="headingSix" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
           aria-controls="collapseSix">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Wie erreichst du die
          NüRenta
          und was ist bei einem Schaden zu tun?</p>
      </div>
      <div id="collapseSix" class="collapse" aria-labelledby="headingSix">
        <div class="card-body">
          <p>Du erreichst uns ganz einfach per Telefon unter <a href="tel:02319776390">02319776390</a>, kannst uns
            eine
            Email an <a href="mailto:info@nuerenta.de">info@nuerenta.de</a>
            schicken oder uns via <a href="https://www.facebook.com/bleibtschadenfrei/">Facebook</a> kontaktieren. Im
            Schadenfall stehen Dir unsere Schadenformulare unter <a
              href="https://www.nuerenta.de/Schadensmeldungen">www.nuerenta.de/Schadensmeldungen</a>
            zur Verfügung oder Du rufst die Schaden-Hotline unter der
            Telefonnummer <a href="tel:08005316666">08005316666</a> an.</p>
        </div>
      </div>
    </div>
    <div class="card question-box mt-3">
      <div class="card-header" id="headingSeven" data-toggle="collapse" data-target="#collapseSeven"
           aria-expanded="false" aria-controls="collapseSeven">
        <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                       class="arrow-down"/>Kann das Geschenkepäckchen
          auch für Geschwisterkinder bestellt werden?</p>
      </div>
      <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven">
        <div class="card-body">
          <p>Selbstverständlich kannst Du auch für Geschwisterkinder ein Geschenkepäckchen bestellen. Auch für ältere
            Geschwister haben wir besondere Geschenke im Paket.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content-slider">
  <div class="container py-5">
    <div id="heards-img-3">
      <img [src]="heardsIMGOne">
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <h2 class="content-view-header text-center">Referenzen</h2>
        <hr class="hr-bar">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-s-12 col-lg-4 mt-5 smaller-devices-mt-spacing">
        <div class="row speachbubble mr-3 ml-3">
          <div class="row defaultheight mr-3 ml-3">
            <p class="mt-3">„kostenloses Krankenhaustagegeld ist dabei 💖schöne Aktionen die ihr immer macht.
              Dankeschön 💕💖🎯“ </p>
          </div>
        </div>
        <div class="row mt-4 speachbubble-name">
          <p>Saskia</p>
        </div>
        <div class="row speachbubble-name">
          <p class="small">26 Jahre, Mutter einer Tochter, aus Hombruch</p>
        </div>
      </div>
      <div class="col-s-12 col-lg-4 mt-5 smaller-devices-mt-spacing">
        <div class="row speachbubble speachbubble-blue mr-3 ml-3">
          <div class="row defaultheight mr-3 ml-3">
            <p class="mt-3">„Mein Sohn hat sich riesig über sein Geschenk Paket gefreut und ohne Biene maja geht er
              nie
              mehr aus dem Haus. Danke sehr" </p>
          </div>
        </div>
        <div class="row mt-4 speachbubble-name">
          <p>Kevin</p>
        </div>
        <div class="row speachbubble-name">
          <p class="small">34 Jahre, zweifacher Vater, aus Wickede</p>
        </div>
      </div>

      <div class="col-s-12 col-lg-4 mt-5 smaller-devices-mt-spacing">
        <div class="row speachbubble mr-3 ml-3">
          <div class="row defaultheight mr-3 ml-3">
            <p class="mt-3">„Ich habe den Willi, das Buch und body bekommen. Wobei beim body der Druck schon riesig
              ist.
              Hätte mich über den Schlüsselanhänger auch sehr gefreut“</p>
          </div>
        </div>
        <div class="row mt-4 speachbubble-name">
          <p>Sarah</p>
        </div>
        <div class="row speachbubble-name">
          <p class="small">29 Jahre, Mutter, aus Hörde</p>
        </div>
      </div>
    </div>
  </div>
</section>
