import {Component, OnInit} from '@angular/core';

import {
  IMAGE_WELCOMEPACKAGEFOUR,
  IMAGE_FINISHED
} from '../const/constants';

@Component({
  selector: 'app-formfinished',
  templateUrl: './formfinished.component.html',
  styleUrls: ['./formfinished.component.scss']
})
export class FormfinishedComponent implements OnInit {

  headerformIMG = IMAGE_WELCOMEPACKAGEFOUR;
  finishedIMG = IMAGE_FINISHED;

  constructor() {
  }

  ngOnInit() {
  }

}
