<div class="header-bar">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <a href="/home"><img alt="logo" class="logo my-3" [src]="logo"/></a>
      </div>
      <div class="col-6">
        <a class="btn check-btn float-right pl-5 pr-5" routerLink="/form">Bestellen!</a>
      </div>
    </div>
  </div>
</div>
