import {Injectable} from '@angular/core';
import {User} from '../form/user';
import * as AWS from 'aws-sdk';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AWSService {
    ses: AWS.SES;
    params: AWS.SES.SendEmailRequest;

    constructor() {
        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: environment.POOLID
            }),
            region: 'eu-central-1'
        });
        this.ses = new AWS.SES({
                region: 'eu-west-1'
            }
        );
    }

    async sendMail(user: User) {
        try {
            this.params = {
                Destination: {
                    ToAddresses: [environment.MAIL]
                },
                Source: environment.MAIL,
                Message: {
                    Subject: {Data: 'Bestellung Geschenkpäckchen'},
                    Body: {
                        Html: {
                            Data: `<tr>                                 
                             <h2>Bestellung Geschenkpäckchen</h2>
                             <td>
                                 <ul>
                                 <li><b>Geschlecht: </b>${user.gender}</li>
                                 <li><b>Vorname: </b>${user.firstName}</li>
                                 <li><b>Nachname: </b>${user.lastName}</li>                                  
                                 <li><b>Straße: </b>${user.street}</li>
                                 <li><b>Hausnummer: </b>${user.streetNumber}</li>
                                 <li><b>PLZ: </b>${user.zip}</li>
                                 <li><b>Stadt: </b>${user.city}</li>                                      
                                 <li><b>E-Mail: </b>${user.email}</li>
                                 <li><b>Telefonnummer: </b>${user.phone}</li>
                                 <li><b>Name Kind: </b>${user.nameChild}</li>
                                 <li><b>Geburtstag Kind (tt.mm.jjjj): </b>${user.birthChild}</li>
                                 <li><b>Möchtest Du im Krankheitsfall die beste Behandlung für Dein Kind durch 
                                 Spezialisten und/oder die Unterbringung im 
                                 Ein-/Zweibettzimmer?: </b>${user.medicationChild}</li>
                                 <li><b>Nachricht: </b>${user.message}</li>
                                 <li><b>Einwilligung der Datenschutzbestimmungen: </b>${user.privacy}</li>
                                 <li><b>Einwilligung zur Anruferlaubnis: </b>${user.telconsent}</li>
                                 </ul>
                             </td>
                        </tr>`
                        }
                    }
                }
            };
            const sesResponse = await this.ses.sendEmail(this.params).promise();
            // console.log(`sendMail requestId: ${sesResponse.$response.requestId} and messageId: ${sesResponse.MessageId}`);
            return 'OK';
        } catch (e) {
            // console.error(`sendMail unexpected: ${e.message}`);
            return 'NOT OK';
        }
    }
}
