import { Component, OnInit } from '@angular/core';
import {
  IMAGE_HEARDSONE,
  IMAGE_HEARDSTWO,
  ICON_ARROWDOWN,
  ICON_ARROWUP,
  IMAGE_HEADER,
  IMAGE_ICONONE,
  IMAGE_ICONTHREE,
  IMAGE_ICONTWO,
  IMAGE_PERSON,
  IMAGE_WELCOMEPACKAGEONE,
  IMAGE_WELCOMEPACKAGETWO,
  IMAGE_WELCOMEPACKAGETHREE,
  IMAGE_WELCOMEPACKAGEFOUR,
  IMAGE_WELCOMEPACKAGECOMPLETE,
  IMAGE_FREEINSURANCE,
  IMAGE_FREEINSURANCETWO,
  IMAGE_HEADERHOME
} from '../const/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  heardsIMGOne = IMAGE_HEARDSONE;
  heardsIMGTwo = IMAGE_HEARDSTWO;
  personIMG = IMAGE_PERSON;
  iconOne = IMAGE_ICONONE;
  iconTwo = IMAGE_ICONTWO;
  iconThree = IMAGE_ICONTHREE;
  packageOneIMG = IMAGE_WELCOMEPACKAGEONE;
  packageTwoIMG = IMAGE_WELCOMEPACKAGETWO;
  packageThreeIMG = IMAGE_WELCOMEPACKAGETHREE;
  packageFourIMG = IMAGE_WELCOMEPACKAGEFOUR;
  packageCompleteIMG = IMAGE_WELCOMEPACKAGECOMPLETE;
  freeInsuranceIMG = IMAGE_FREEINSURANCE;
  freeInsuranceTwoIMG = IMAGE_FREEINSURANCETWO;
  default1 = IMAGE_HEADER;
  default2 = IMAGE_HEADER;
  default3 = IMAGE_HEADER;
  headerHomeIMG = IMAGE_HEADERHOME;

  arrowUp = ICON_ARROWUP;
  arrowDown = ICON_ARROWDOWN;

  constructor() { }

  ngOnInit() {
  }

}
