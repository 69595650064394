import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from './user';
import {ICON_ARROWDOWN, ICON_ARROWUP, IMAGE_WELCOMEPACKAGEFOUR} from '../const/constants';
import {AWSService} from "../service/aws.service";
import {dateValidator} from "../const/custom-validator";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  currentUser = new User;
  headerformIMG = IMAGE_WELCOMEPACKAGEFOUR;

  gender: String[] = [
    'Herr',
    'Frau'
  ];

  submitForm: FormGroup;
  submitted = false;

  arrowUp = ICON_ARROWUP;
  arrowDown = ICON_ARROWDOWN;

  constructor(private formBuilder: FormBuilder, private awsService: AWSService) {
  }

  ngOnInit() {
    this.submitForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      streetNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      nameChild: new FormControl('', [Validators.required]),
      birthChild: new FormControl('', [Validators.required, dateValidator]),
      medicationChild: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.maxLength(255)]),
      privacy: new FormControl('', [Validators.required]),
      telconsent: new FormControl('', [Validators.required])
    });
  }

  // get all formcontrols of group
  get formControls() {
    return this.submitForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.currentUser.gender = this.submitForm.get('gender').value;
    this.currentUser.firstName = this.submitForm.get('firstName').value;
    this.currentUser.lastName = this.submitForm.get('lastName').value;
    this.currentUser.zip = this.submitForm.get('zip').value;
    this.currentUser.city = this.submitForm.get('city').value;
    this.currentUser.street = this.submitForm.get('street').value;
    this.currentUser.streetNumber = this.submitForm.get('streetNumber').value;
    this.currentUser.email = this.submitForm.get('email').value;
    this.currentUser.phone = this.submitForm.get('phone').value;
    this.currentUser.nameChild = this.submitForm.get('nameChild').value;
    this.currentUser.birthChild = this.submitForm.get('birthChild').value;
    this.currentUser.medicationChild = this.submitForm.get('medicationChild').value;
    this.currentUser.message = this.submitForm.get('message').value;
    this.currentUser.privacy = "Ja";
    this.currentUser.telconsent = "Ja";
    // stop here if form is invalid
    if (this.submitForm.invalid) {
      return;
    }
    this.awsService.sendMail(this.currentUser);
  }

  back() {
    this.currentUser = null;
  }
}
