export const IMAGE_LOGO = '../assets/img/NuerentaLogo.png';
export const IMAGE_HEADER = '../assets/img/headerPic.png';
export const IMAGE_HEARDSONE = '../assets/img/heards.png';
export const IMAGE_HEARDSTWO = '../assets/img/heards2.png';
export const IMAGE_PERSON = '../assets/img/agentur.jpg';
export const IMAGE_ICONONE = '../assets/icon/present.jpg';
export const IMAGE_ICONTWO = '../assets/icon/stork.jpg';
export const IMAGE_ICONTHREE = '../assets/icon/ambulance.png';
export const ICON_ARROWUP = '../assets/img/arrow_up.svg';
export const ICON_ARROWDOWN = '../assets/img/arrow_down.svg';
export const IMAGE_HEADERHOME = '../assets/img/formheader.jpg';
export const IMAGE_WELCOMEPACKAGECOMPLETE = '../assets/img/complete_package.jpg';
export const IMAGE_WELCOMEPACKAGEONE = '../assets/img/bees.jpg';
export const IMAGE_WELCOMEPACKAGETWO = '../assets/img/baby_shirt_shoes.jpg';
export const IMAGE_WELCOMEPACKAGETHREE = '../assets/img/trailer.jpg';
export const IMAGE_WELCOMEPACKAGEFOUR = '../assets/img/baby_shirt_shoes2.jpg';
export const IMAGE_FREEINSURANCE = '../assets/img/freeInsurance.jpg';
export const IMAGE_FREEINSURANCETWO = '../assets/img/AdobeStock_110015625.jpeg';
export const IMAGE_FINISHED = '../assets/img/finished.png';
