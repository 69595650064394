<div [style.backgroundImage]="'url('+ headerformIMG +')'" class="headerpic">
</div>

<section class="headertitle">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-10">
        <h1 class="header-text text-center">
          <span class="font-weight-bold package-color">Geschenk&shy;päckchen</span>
          <span> - Bestellformular</span>
        </h1>
      </div>
    </div>
  </div>
</section>

<section class="form">
  <div class="container">
    <div class="row mt-5 mb-4 justify-content-center">
      <div class="col-10">
        <h3 class="form-view-person-data">Persönliche Daten</h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-10">
        <form [formGroup]="submitForm">
          <div class="row">
            <div class="form-group col-lg-3 col-md-12">
              <label for="select-gender">Anrede</label>
              <div class="select-design">
                <select class="custom-select form-control form-design"
                        formControlName="gender"
                        id="select-gender"
                        [ngClass]="{ 'is-invalid': submitted && formControls.gender.errors }">
                  <option value="" hidden>Bitte wählen ...</option>
                  <option *ngFor="let g of gender" [value]="g">{{g}}</option>
                </select>
              </div>
              <div *ngIf="submitted && formControls.gender.errors" class="invalid-feedback invalid-text-shown">
                <div *ngIf="formControls.gender.errors.required">Bitte wähle ein Geschlecht</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="firstName">Vorname</label>
              <input type="text"
                     id="firstName"
                     formControlName="firstName"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.firstName.errors }"/>
              <div *ngIf="submitted && formControls.firstName.errors" class="invalid-feedback">
                <div *ngIf="formControls.firstName.errors.required">Bitte Vornamen eingeben</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label for="lastName">Nachname</label>
              <input type="text"
                     id="lastName"
                     formControlName="lastName"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.lastName.errors }"/>
              <div *ngIf="submitted && formControls.lastName.errors" class="invalid-feedback">
                <div *ngIf="formControls.lastName.errors.required">Bitte Nachnamen eingeben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="street">Straße</label>
              <input type="text"
                     id="street"
                     formControlName="street"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.street.errors }"/>
              <div *ngIf="submitted && formControls.street.errors" class="invalid-feedback">
                <div *ngIf="formControls.street.errors.required">Bitte Straße eingeben</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label for="streetNumber">Hausnummer</label>
              <input type="text"
                     id="streetNumber"
                     formControlName="streetNumber"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.streetNumber.errors }"/>
              <div *ngIf="submitted && formControls.streetNumber.errors" class="invalid-feedback">
                <div *ngIf="formControls.streetNumber.errors.required">Bitte Hausnummer eingeben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="zip">Postleitzahl</label>
              <input type="text"
                     id="zip"
                     formControlName="zip"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.zip.errors }"/>
              <div *ngIf="submitted && formControls.zip.errors" class="invalid-feedback">
                <div *ngIf="formControls.zip.errors.required">Bitte Postleitzahl eingeben</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label for="city">Stadt</label>
              <input type="text"
                     id="city"
                     formControlName="city"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.city.errors }"/>
              <div *ngIf="submitted && formControls.city.errors" class="invalid-feedback">
                <div *ngIf="formControls.city.errors.required">Bitte Wohnort eingeben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="email">E-Mail</label>
              <input type="email"
                     id="email"
                     formControlName="email"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }"/>
              <div *ngIf="submitted && formControls.email.errors" class="invalid-feedback">
                <div *ngIf="formControls.email.errors.email">Bitte gültige E-Mail angeben</div>
                <div *ngIf="formControls.email.errors.required">Bitte E-Mail angeben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="phone">Telefonnummer</label>
              <input type="text"
                     id="phone"
                     formControlName="phone"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }"/>
              <div *ngIf="submitted && formControls.phone.errors" class="invalid-feedback">
                <div *ngIf="formControls.phone.errors.required">Bitte Telefonnummer angeben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="nameChild">Name Kind</label>
              <input type="text"
                     id="nameChild"
                     formControlName="nameChild"
                     class="form-control form-design"
                     [ngClass]="{ 'is-invalid': submitted && formControls.nameChild.errors }"/>
              <div *ngIf="submitted && formControls.nameChild.errors" class="invalid-feedback">
                <div *ngIf="formControls.nameChild.errors.required">Bitte Namen des Kindes angeben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="birthChild">Geburtstag Kind</label>
              <input type="text"
                     id="birthChild"
                     formControlName="birthChild"
                     class="form-control form-design"
                     placeholder="tt.mm.jjjj"
                     [ngClass]="{ 'is-invalid': submitted && formControls.birthChild.errors }"/>
              <div *ngIf="submitted && formControls.birthChild.errors" class="invalid-feedback">
                <div *ngIf="formControls.birthChild.errors.required">Bitte Geburtstag des Kindes angeben</div>
                <div *ngIf="submitForm.controls.birthChild.invalid && !formControls.birthChild.errors.required">Bitte
                  gültiges Geburtsdatum angeben
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6 col-md-12">
              <label for="medicationChild">Möchtest Du im Krankheitsfall die beste Behandlung für Dein Kind durch
                Spezialisten und/oder die Unterbringung im Ein-/Zweibettzimmer?</label>
              <label for="medicationChild" class="small">Gerne legen wir Dir dann ein Angebot in das
                Geschenkpäckchen</label>
              <div id="medicationChild" class="form-check form-check-inline form-control form-control-radio-field"
                   [ngClass]="{ 'is-invalid': submitted && formControls.medicationChild.errors }">
                <div class="radio-button-design">
                  <input type="radio" formControlName="medicationChild" value="Ja" id="medicationChildCheck1"
                         class="form-check-input">
                  <label for="medicationChildCheck1" class="form-check-label">Ja</label>
                  <input type="radio" formControlName="medicationChild" value="Nein" id="medicationChildCheck2"
                         class="form-check-input">
                  <label for="medicationChildCheck2" class="form-check-label ml-5">Nein</label>
                </div>
              </div>
              <div *ngIf="submitted && formControls.medicationChild.errors" class="invalid-feedback">
                <div *ngIf="formControls.medicationChild.errors.required">Bitte ankreuzen</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label for="message">Nachricht</label>
              <textarea type="text"
                        id="message"
                        formControlName="message"
                        class="form-control longInput form-design"
                        cols="20" rows="3"
                        [ngClass]="{ 'is-invalid': submitted && formControls.message.errors }">
              </textarea>
              <div *ngIf="submitted && formControls.message.errors" class="invalid-feedback">
                <div *ngIf="formControls.message.errors.required">Maximal 255 Zeichen</div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="form-group col-lg-6 col-md-12">
              <div id="privacy" class="form-check form-check-inline form-control form-control-radio-field"
                   [ngClass]="{ 'is-invalid': submitted && formControls.privacy.errors }">
                <div class="radio-button-design">
                  <input type="checkbox" formControlName="privacy" id="privacy1" value="akzeptiert"
                         class="form-check-input" required>
                  <label for="privacy1" class="form-check-label">Ich akzeptiere die Datenschutzbestimmungen</label>
                </div>
              </div>
              <div *ngIf="submitted && formControls.privacy.errors" class="invalid-feedback privacy-margin-top-more-lines">
                <div *ngIf="formControls.privacy.errors.required">Bitte ankreuzen</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-12 margin-bottom-mobile">
              <div id="telconsent" class="form-check form-check-inline form-control form-control-radio-field"
                   [ngClass]="{ 'is-invalid': submitted && formControls.telconsent.errors }">
                <div class="radio-button-design">
                  <input type="checkbox" formControlName="telconsent" id="telconsent1" value="akzeptiert"
                         class="form-check-input" required>
                  <label for="telconsent1" class="form-check-label">Ich akzeptiere die Einwillung zur
                    Anruferlaubnis</label>
                </div>
              </div>
              <div *ngIf="submitted && formControls.telconsent.errors" class="invalid-feedback telconsent-margin-top-more-lines">
                <div *ngIf="formControls.telconsent.errors.required">Bitte ankreuzen</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12 margin-top-mobile">
              <p class="small ">Damit wir Dein Geschenkpäckchen fertig stellen können und um
                Dich zur kostenlosen
                Krankenhaustagegeld-Versicherung informieren zu können, bitten wir um die Erlaubnis Dich anrufen zu
                dürfen.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card question-box smaller-devices-mt-spacing">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                     aria-expanded="false"
                     aria-controls="collapseOne">
                  <p><img alt="arrow_up" [src]="arrowUp" class="arrow-up"/> <img alt="arrow_down" [src]="arrowDown"
                                                                                 class="arrow-down"/>Datenschutzbestimmungen
                    und Einwilligung zur Anruferlaubnis </p>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne">
                  <div class="card-body">
                    <p>Ihre personenbezogenen Daten werden bei uns vertraulich behandelt. Ihre persönlichen Daten, die
                      Sie
                      uns
                      im
                      Rahmen einer Kontaktaufnahme zur Verfügung stellen, werden bei uns gespeichert und im Rahmen der
                      Kontaktaufahme verarbeitet. Hier handelt es sich in der Regel um Ihren Namen, Ihre E-Mail-Adresse,
                      Ihre
                      Anschrift, Ihre Telefonnummer oder Telefaxnummer.
                      <br><br>
                      Wir nutzen Ihre Daten, um Ihnen attraktive Angebote zusenden zu können. Wir nutzen Ihre Daten für
                      eigene
                      Zwecke, geben diese jedoch NICHT an Dritte weiter. Der Nutzung Ihrer persönlichen Daten können Sie
                      jederzeit
                      durch schriftliche Mitteilung an uns oder per Mail an uns widersprechen. Nach Erhalt Ihres
                      Widerrufes
                      werden
                      wir die weitere Zusendung von Informationen umgehend einstellen.Mit dem Besuch dieser Webseite
                      können
                      Informationen über den Zugriff gespeichert werden. Diese Daten wie Uhrzeit, Datum und die
                      betrachtete
                      Seite
                      gehören nicht zu den personenbezogenen Daten, sondern sind anonymisiert. Dies werden nur aus
                      statistischen
                      Zwecken erfasst. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken,
                      erfolgt
                      nicht.
                      <br><br>
                      Der Anbieter weist ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                      Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch
                      Dritte
                      geschützt werden kann.
                      <br><br>
                      <b>Einwilligung zur Anruferlaubnis</b>
                      <br><br>
                      Der Kunde oder Absender des Kontaktformulars ist damit einverstanden, künftig zum Zweck der
                      Beratung und
                      Information(Werbung) über Versicherungsprodukte und Finanzdienstleistungen, der Unternehmen
                      Nürnberger
                      Versicherung und Nürenta GmbH von den zuständigen Vermittlern angerufen zu werden.
                      <br><br>
                      Besondere personenbezogene Daten
                      <br><br>
                      Soweit erforderlich, umfasst die Einwilligung auch die Verarbeitung besonderer Kategorien
                      personenbezogener Daten im Sinne von Art.9 Abs. 1 EU Datenschutzgrundverordnung (insbesondere
                      Angaben
                      über die Gesundheit der zu versichernden Personen). Die datenschutzrechtlichen Belange werden
                      uneingeschränkt gewährleistet.
                      <br><br>
                      <b>Einwilligungserklärung in die Datenverarbeitung</b>
                      <br><br>
                      Ich bin damit einverstanden, dass die Nürenta GmbH und ihre zuständigen Vermittler meine
                      angegebenen
                      Daten ausschließlich zur Bearbeitung meines Anliegens erheben, verarbeiten und nutzen dürfen.
                      Darüber
                      hinaus erfolgt eine Weitergabe der Daten an Dritte nur zur Erbringung der gewünschten
                      Dienstleistung
                      oder wenn eine gesetzliche Verpflichtung dazu besteht. Ihre Einverständniserklärung können Sie
                      jederzeit
                      ganz oder teilweise widerrufen. Ihren Widerruf können Sie uns gegenüber per E-Mail an <a
                        href="mailto:info@nuerenta.de">info@nuerenta.de</a>,
                      telefonisch unter <a href="tel:02319776390">0231-977639-0</a>, per Fax an 0231-977639-22 oder per
                      Post
                      an Nürenta Vertriebs GmbH,
                      Westfalendamm 280, 44141 Dortmund, erklären.
                      <br><br>
                      Weitere Datenschutzbestimmungen sehen Sie hier
                      <a href="https://nuerenta.de/#datenschutz">https://nuerenta.de/#datenschutz</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="form-group col-lg-3 col-md-12">
              <a class="btn check-btn btn-block" (click)="onSubmit()"
                 [routerLink]="!submitForm.invalid ? ['/finished']:[]">Anfordern</a>
            </div>
            <div class="form-group col-lg-3 col-md-12">
              <a class="btn back-btn btn-block" (click)="back()" routerLink="/home">Zurück</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section>

</section>
