<section class="contentheigh">
  <div [style.backgroundImage]="'url('+ headerformIMG +')'" class="headerpic">
  </div>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-12">
        <h2 class="content-view-header text-center header-text font-weight-bold formheadercolour">
          Geschenk&shy;päckchen</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <h5 class="headercolour">Erfolgreich bestellt!</h5>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <p class="text-center">Vielen Dank für Deine Bestellung. Wir werden Dein Päckchen zusammenstellen und uns
          demnächst telefonisch bei Dir melden.</p>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <img alt="iconFinished" [src]="finishedIMG" class="image-finished">
    </div>
    <div class="row  justify-content-center">
      <div class="form-group col-lg-3 col-md-12 mt-5">
        <a class="btn back-btn btn-block" routerLink="/home">Zurück</a>
      </div>
    </div>
  </div>
</section>


