<section class="footer py-3 mt-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-centered mt-3">
        <img class="" alt="logoWhite" [src]="logo">
      </div>
    </div>
    <div class="container mt-3 footer-links">
      <div class="row justify-content-center">
        <div class="col-5">
          <a class="text-grey float-right" href="https://nuerenta.de/#datenschutz">Datenschutz</a>
        </div>
        <div class="col-5">
          <a class="text-grey float-left" href="https://nuerenta.de/?page_id=730">Impressum</a>
        </div>
      </div>
    </div>
    <div class="container footer-text">
      <div class="row justify-content-center mt-1">
        <div class="col-xs-6 col-sm-7 col-md-6 col-lg-6">
          <h6 class="text-center">NüRenta GmbH | <a class="footer-text" href="https://de-de.facebook.com/pg/bleibtschadenfrei/posts/">#bleibtschadenfrei</a></h6>
        </div>
      </div>
    </div>
  </div>
</section>
