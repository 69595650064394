import {Component, OnInit} from '@angular/core';
import {IMAGE_LOGO} from '../const/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logo = IMAGE_LOGO;

  constructor() {
  }

  ngOnInit() {
  }

}
